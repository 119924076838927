<template>
  <!-- <j-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    :fullscreen="true"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"> -->
    
    <!-- <j-form-container :disabled="formDisabled"> -->
      <a-form-model ref="form" slot="detail" :model="model" :rules="validatorRules">
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name" label="任务名称">
          <a-input :disabled="!stageDisabled.includes('name')" placeholder="请输入任务名称" v-model="model.name" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="assignName" label="任务分配人">
          <search-select
            :disabled="!stageDisabled.includes('assignName')" 
            :multi="false"
            :btnShow="true"
            :value="model.assignName"
            @selUsers="selUsers"></search-select>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="chargeName" label="任务负责人">
          <search-select
            :disabled="!stageDisabled.includes('chargeName')" 
            :multi="false"
            :btnShow="true"
            :value="model.chargeName"
            @selUsers="chargeName"></search-select>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="projectId" label="项目">
          <JSelectMultiProject :disabled="!stageDisabled.includes('projectId')" :value="model.projectId" @select="select" :query-config="selectProjectQueryConfig"></JSelectMultiProject>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="budgetAmount" label="任务预算奖金">
          <a-input-number :disabled="!stageDisabled.includes('budgetAmount')" style="width: 100%" :min="0" placeholder="请输入预算奖金" v-model="model.budgetAmount" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="estimatedHour" label="分配人预估工时">
          <a-input-number :disabled="!stageDisabled.includes('estimatedHour')" style="width: 100%" placeholder="请输入预估工时" v-model="model.estimatedHour" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="reviewHour" label="负责人预估工时">
          <a-input-number :disabled="!stageDisabled.includes('reviewHour')" style="width: 100%" placeholder="请输入预估工时" v-model="model.reviewHour" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="auditedHour" label="审批工时">
          <a-input-number :disabled="!stageDisabled.includes('auditedHour')" style="width: 100%" placeholder="请输入审批工时" v-model="model.auditedHour" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="planStartTime" label="计划开始时间">
          <a-date-picker :disabled="!stageDisabled.includes('planStartTime')" style="width: 100%" showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.planStartTime" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="planEndTime" label="	计划结束时间">
          <a-date-picker :disabled="!stageDisabled.includes('planEndTime')" style="width: 100%" showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.planEndTime" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="startTime" label="实际开始时间">
          <a-date-picker :disabled="!stageDisabled.includes('startTime')" style="width: 100%" showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.startTime" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="endTime" label="实际结束时间">
          <a-date-picker :disabled="!stageDisabled.includes('endTime')" style="width: 100%" showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.endTime" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="description" label="任务描述">
          <a-textarea
            :disabled="!stageDisabled.includes('description')" 
            v-model="model.description"
            placeholder="请输入任务描述"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="attachments" label="附件">
          <j-upload :disabled="!stageDisabled.includes('description')"  v-model="model.attachments"></j-upload>
        </a-form-model-item>
      </a-form-model>
    <!-- </j-form-container> -->
  <!-- </j-modal> -->
</template>

<script>
  import { baseUrl } from "@/api/api.js"
  import { GetAtion, HttpAction } from "@/utils/http"
  import SearchSelect from '@/components/SearchSelectUser/SearchSelect';
  import JSelectMultiProject from '@/views/hrProjectManage/hrTask/modules/JSelectMultiProject';

  export default {
    name: "HrProjectModal",
    components: {
      SearchSelect,
      JSelectMultiProject
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      },
    },
    data () {
      return {
        title:"操作",
        visible: false,
        model: {
          activitiType: 1,
          stage: 1
        },
        // 选择用户查询条件配置
        selectUserQueryConfig: [
          {key: 'phone', label: '电话'},
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
        // 选择项目查询条件配置
        selectProjectQueryConfig: [],
        confirmLoading: false,
        validatorRules:{
          name: [{ required: true, message: '请输入任务名称!' }],
          assignName: [{ required: true, message: '请选择任务分配人!' }],
          chargeName: [{ required: true, message: '请选择任务负责人!' }],
          planStartTime: [{ required: true, message: '请选择计划开始时间!' }],
          planEndTime: [{ required: true, message: '请选择计划结束时间!' }],
          estimatedHour: [{ required: true, message: '请输入预估工时!' }],
          // budgetAmount: [{ required: true, message: '请输入预算奖金!' }],
          description: [{ required: true, message: '请输入任务描述!' }],
        },
        url: {
          add: baseUrl + "/hr-server/hrTask/add",
          edit: baseUrl + "/hr-server/hrTask/edit",
        },
        hrProjectMember: '',
        processName: '',
        procDefId: '',
        feildArr: [
          'name', 
          'assignName', 
          'chargeName', 
          'planStartTime', 
          'planEndTime', 
          'estimatedHour', 
          'budgetAmount', 
          'description',
          'projectId',
          'auditedHour',
          'endTime',
          'startTime',
          'reviewHour',
          'attachments'
        ]
      }
    },
    created () {
    },
    watch: {
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
      stageDisabled() {
        if (!this.formDisabled) return this.feildArr;
        // 1.先判断有无id，就是是否新增
        if (this.model.id) {
          if (!this.model.stage || this.model.stage >= 4 || this.model.stage < 1) return ([]);
          // 2.修改表单判断流程类型
          if (this.model.activitiType == 1) {
            if (this.model.stage == 1) {

              this.validatorRules.reviewHour = [{ required: true, message: '请输入负责人预估工时!' }]
              return ['reviewHour']
            } else if (this.model.stage == 2) {

              return this.feildArr
            } else if (this.model.stage == 3) {

              this.validatorRules.endTime = [{ required: true, message: '请选择结束时间!' }]
              return ['endTime']
            }
          } else if (this.model.activitiType == 2) {
            if (this.model.stage == 1) {

              return this.feildArr
            } else if (this.model.stage == 2) {

              this.validatorRules.endTime = [{ required: true, message: '请选择结束时间!' }]
              return ['endTime']
            }
          } else if (this.model.activitiType == 3) {
            if (this.model.stage == 1) {
              this.validatorRules.endTime = [{ required: true, message: '请选择结束时间!' }]
              return ['endTime']
            }
          }
        } else {
          this.model.stage = 1
          return this.feildArr
        }
        
      }
    },
    methods: {
      selUsers(value) {
        if (value && value.length) {
          this.model.assignName = value[0].key
        } else {
          this.model.assignName = ''
        }
      },
      select(value) {
        if (value.length) {
          this.model.projectId = value[0].id
        } else {
          this.model.projectId = ''
        }
        
      },
      // selectUser(value) {
      //   if (value?.length) {
      //     this.model.assignUid = value[0].id
      //   } else {
      //     this.model.assignUid = ''
      //   }
      // },
      chargeName(value) {
        if (value && value.length) {
          this.model.chargeName = value[0].key
        } else {
          this.model.chargeName = ''
        }
      },
      
      add (obj) {
        //初始化默认值
        this.edit(obj);
      },
      edit (record) {
        this.procDefId = record.procDefId
        this.processName = record.processName
        this.model = Object.assign({}, record.domain);
        this.model.executeType = 1
        this.visible = true;
      },
      close () {
        // this.$emit('close');
        this.visible = false;
        // this.$refs.form.clearValidate();
      },
      handleOk (cb, flag) {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
              method = 'put';
            }
            // 判断是否是在审批流程
            if (typeof flag === 'boolean') {
              // 1.判断同意还是不同意，再决定stage步骤
              if (this.model.activitiType == 1) {
                if (this.model.stage > 1) {
                  if (flag) {
                    this.model.stage = this.model.stage == 4 ? this.model.stage : this.model.stage + 1
                  } else {
                    this.model.stage = this.model.stage - 1
                  }
                  
                } else {
                  this.model.stage = flag ? this.model.stage + 1 : this.model.stage
                }
                
              } else if (this.model.activitiType == 2) {
                if (flag) {
                  this.model.stage = this.model.stage == 1 ? this.model.stage + 1 : 4
                } else {
                  this.model.stage = this.model.stage == 1 ? this.model.stage : this.model.stage - 1
                }
              } else if (this.model.activitiType == 3) {
                if (flag) {
                  this.model.stage = 4
                } 
              }
            }
            
            HttpAction(httpurl,{ 
              domain: this.model,
              processName: this.processName,
              procDefId: this.procDefId
            },method).then((res)=>{
              if(res.success) {
                if (method == 'post') {
                  if (res.result) {
                    that.model.activitiId = res.result
                    that.$emit('ok', this.model)
                  }
                } else {
                  that.$emit('ok', this.model)
                }
                typeof cb === 'function' &&  cb(valid);
              } else {
                that.$emit('close')
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }else{
            that.$emit('close')
            return false;
          }
        })
      },
      handleCancel () {
        this.close()
      },
    }
  }
</script>

<style lang="less" scoped>
.select_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>